/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Zakaria Djebbes",
  description:
    "I'm an avid enthusiast in the realm of computer science, algorithms, and video games. As a developer, my passion lies in crafting diverse projects, spanning from websites and desktop apps to immersive video games. I thrive on the thrill of exploring, creating, and innovating within the ever-evolving landscape of technology.",
  og: {
    title: "Zakaria Djebbes Portfolio",
    type: "website",
    url: "https://lanathanel.com/",
  },
};

//Home Page
const greeting = {
  title: "Zakaria Djebbes",
  logo_name: "Zakaria Djebbes",
  nickname: "lanathanel",
  subTitle:
    "I'm a computer science enthusiast and .NET developer passionate about crafting diverse projects. I thrive on exploring and innovating with new technologies.",
  resumeLink:
    "https://share.lanathanel.com/jalal.jpg",
  portfolio_repository: "https://github.com/ZakariaDjebbes/LanaHome",
  githubProfile: "https://github.com/ZakariaDjebbes",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/ZakariaDjebbes",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/zakaria-djebbes-a2029b230/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:zakaria.djebbes@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  }
];

const skills = {
  data: [
    {
      title: "Backend Developpement",
      fileName: "BackendImg",
      skills: [
        "‣ High experience with server-side programming languages including C# (ASP.NET), Java (Spring) and Typescript (Express)",
        "‣ Building robust and scalable backend systems for production environments.",
        "‣ Expertise in designing and implementing RESTful APIs for seamless communication between front-end and back-end components",
        "‣ Writing tested, clean and robust code in application of good practices and principles such as SOLID and DRY.",
        "‣ Investigating and debugging issues, with a strong ability to identify and fix bugs efficiently.",
        "‣ Proficient in database management and optimization, including SQL and NoSQL databases such as MySQL, PostgreSQL, SQL Server and MongoDB.",
        "‣ Designing and optimizing data storage solutions to handle large volumes of data efficiently.",
        "‣ Monitoring and optimizing application performance, identifying and resolving bottlenecks.",
        "‣ Version control through Git.",
      ],
      softwareSkills: [
        {
          skillName: "C#",
          fontAwesomeClassname: "devicon:csharp",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Dotnet",
          fontAwesomeClassname: "logos:dotnet",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "devicon:java-wordmark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Spring",
          fontAwesomeClassname: "devicon:spring",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Typescript",
          fontAwesomeClassname: "skill-icons:typescript",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Express",
          fontAwesomeClassname: "skill-icons:expressjs-dark",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Postgres",
          fontAwesomeClassname: "devicon:postgresql-wordmark",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "logos:mysql",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Mongo",
          fontAwesomeClassname: "devicon:mongodb-wordmark",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "devicon:git",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Frontend Development",
      fileName: "FullStackImg",
      skills: [
        "‣ Experience with modern front-end frameworks :Angular and React.",
        "‣ Experience with desktop frameworks such as WPF and Electron.",
        "‣ Implementing and consuming RESTful APIs to fetch and update data dynamically.",
        "‣ Cross-browser compatibility testing and ensuring a consistent user experience across different platforms.",
        "‣ Integration of third-party libraries and components to enhance functionality and features.",
        "‣ Responsive design techniques for optimal performance on various devices and screen sizes."
      ],
      softwareSkills: [
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Npm",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "WPF",
          fontAwesomeClassname: "devicon:dot-net",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Electron",
          fontAwesomeClassname: "simple-icons:electron",
          style: {
            color: "#2C8EBB",
          },
        }
      ],
    },
    {
      title: "Cloud and DevOps",
      fileName: "CloudInfraImg",
      skills: [
        "‣ Working with cloud platforms such as Azure for scalable and reliable infrastructure.",
        "‣ Continuous integration and deployment (CI/CD) to streamline the development and deployment processes with Gitlab and Teamcity.",
        "‣ Familiarity with containerization and orchestration tools such as Docker and Kubernetes.",
        "‣ Deploying, hosting and maintaining software on virtual machine instances along with integration of databases (like this portfolio !)",
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Gitlab",
          fontAwesomeClassname: "devicon:gitlab",
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Master's degree in Computer Science (Apprenticeship) at Sorbonne Université, Paris, France",
      subtitle: "Software Science and Technology",
      logo_path: "su_logo.png",
      alt_name: "Sorbonne Université",
      duration: "2022 - 2023",
      descriptions: [
        "‣ Development of fullstack Web Applications.",
        "‣ Contenrization of projects through Docker and Kubernetes.",
        "‣ Project Management and Leadership.",
        "‣ Typing and Static Analysis.",
        "‣ Random Generation Algorithms, Web Algorithms and Data Structures"
      ],
      website_link: "https://sciences.sorbonne-universite.fr/formation-sciences/masters/master-informatique/parcours-stl",
    },
    {
      title: "Master 1 in Computer Science - Sorbonne University Paris Nord, Paris, France",
      subtitle: "Computer Science and Data",
      logo_path: "uspn_logo.png",
      alt_name: "Sorbonne University Paris Nord",
      duration: "2021 - 2022",
      descriptions: [
        "‣ Advanced Databases and Database management.",
        "‣ Algorthmics and Advanced datastructures.",
        "‣ Multithreading and parallel programming.",
        "‣ Introduction to Cloud architectures.",
      ],
      website_link: "https://galilee.univ-paris13.fr/master/master-informatique/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Version Control with Git",
      subtitle: "- Atlassian University",
      logo_path: "atlassian_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/GM66QACK23CV",
      alt_name: "atlassian",
      color_code: "#C2D4FF",
    },
    {
      title: "Agile Project Management",
      subtitle: "- Google",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/GLLO6QACK23CV",
      alt_name: "google",
      color_code: "#DED5DB",
    },
    {
      title: "Introduction to Microsoft Azure Cloud Services",
      subtitle: "- Microsoft",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/GLLXCVACK23CV",
      alt_name: "microsoft",
      color_code: "#FFD1C2",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "Contributing to the design, development, and maintenance of software solutions. Collaborating with cross-functional teams, I analyze requirements, implement robust code, and ensure seamless integration. Actively engaged in code reviews, debugging, and troubleshooting for optimal performance. Stay updated on industry trends to drive innovation and maintain software excellence.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Ingénieur Etude et développement",
          company: "SG Corporate and investment bank",
          company_url: "WIP",
          logo_path: "coursera_logo.png",
          duration: "",
          location: "",
          description:
            "TODO",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Alternships and Internships",
      experiences: [
        {
          title: "BudgetBox Dev back",
          company: "BudgetBox",
          company_url: "",
          logo_path: "coursera_logo.png",
          duration: "",
          location: "",
          description:
            "TODO",
          color: "#000000",
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects involve leveraging a wide array of cutting-edge technology tools, with a particular focus on my expertise in creating programming languages, developing games, building servers, designing APIs, and constructing full-stack software. I am highly experienced in deploying solutions and implementing DevOps practices. Notably, I work extensively with C#, F#, and .NET technologies in these endeavors.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "address_image.svg",
    description:
      "Feel free to reach out to me through any of the above channels. I look forward to connecting with you.",
  },
  phoneSection: {
    title: "Address",
    subtitle:
      "Phone Number : +33 6 98 82 25 67",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
